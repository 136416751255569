<script setup>
import { GrButton } from "@/components/GrComponents";
import { computed, getCurrentInstance, onMounted } from "vue";
import { useStore } from "@/utils/useStore";
import { money } from "@/utils/money";
import { useI18n } from "@/utils/useI18n";

const vm = getCurrentInstance();
const store = useStore();
const i18n = useI18n();

const props = defineProps({
  status: {
    type: String,
    default: "",
  },
  isRecovery: {
    type: Number,
    default: null,
  },
});
const getLosOfGreennSoft = computed({
  get() {
    return store.getters["opportunities/getLosOfGreennSoft"];
  },
});

const saleIsPaid = computed(() => {
  return props.isRecovery && props.status === "paid";
});

const saleIsNotPaid = computed(() => {
  return !props.isRecovery || (props.isRecovery && props.status !== "paid");
});

const titleOpportunity = computed(() => {
  return saleIsPaid.value
    ? i18n.t("opportunities.dashbord.title_to_paid")
    : i18n.t("opportunities.dashbord.title_not_paid");
});

const textButtonOpportunity = computed(() => {
  return saleIsNotPaid.value
    ? i18n.t("opportunities.dashbord.button_not_paid")
    : i18n.t("opportunities.dashbord.button_to_paid");
});

function router() {
  if (saleIsNotPaid.value) {
    return vm.proxy.$router.push({ name: "Opportunities" });
  }

  vm.proxy.$emit("showOpportunityDetails");
}

onMounted(() => {
  if (getLosOfGreennSoft.value == "") {
    store.dispatch("opportunities/getLossOfGreennSofyValue");
  }
});
</script>

<template>
  <div class="feature-opportunities">
    <div class="flex-header">
      <img src="@/assets/img/icons/opportunities/feature.svg" alt="" />
      <p class="title-feature" :class="{ 'max-text': status }">{{ titleOpportunity }}</p>
    </div>

    <p v-if="saleIsNotPaid" class="text-content">
      <span> {{ $t("opportunities.dashbord.description") }} </span>
      <span v-if="getLosOfGreennSoft > 0">
        {{ $t("opportunities.dashbord.description_potential") }}
        <span class="value-loss"> {{ money(getLosOfGreennSoft, "R$") }}</span>
      </span>
    </p>

    <GrButton @click="router()" id="anuncioprofit_btn_verdetalhes" class="color-button">{{
      textButtonOpportunity
    }}</GrButton>
  </div>
</template>
<style lang="scss" scoped>
.feature-opportunities {
  max-width: 440px;
  padding: var(--spacing-8);
  margin-bottom: var(--spacing-8);

  p,
  span {
    font-family: "Plus Jakarta Sans";
  }

  .title-feature {
    font-size: var(--font-lg);
    font-weight: var(--weight-bold);
    color: var(--primary-800);
    line-height: 25.6px;
    white-space: pre-line;
  }
  .max-text {
    max-width: 66%;
  }

  .text-content {
    margin-bottom: var(--spacing-4);
    margin-bottom: var(--spacing-6);
    span {
      font-weight: var(--weight-normal);
      font-size: var(--font-sm);
      font-weight: var(--weight-regular);
      color: var(--gray-200);
      line-height: 25.6px;
      font-size: 16px;
    }

    .value-loss {
      color: var(--yellow-500);
      font-weight: var(--weight-semibold);
    }
  }

  .color-button {
    background-color: #e6af0c;
    color: var(--gray-white);
    width: var(--w-full);

    &:hover {
      background-color: #f6c819;
    }
  }

  .flex-header {
    display: flex;
    gap: var(--spacing-5-6);
    padding-bottom: var(--spacing-4);
    align-items: center;

    svg {
      width: 50px;
      height: 50px;
    }
  }
}
</style>
