<template>
  <div class="container">
    <transition>
      <div>
        <BaseHeader title="Dashboard" class="header mb-5" data-anima="top">
          <div class="top-icons">
            <div class="apps" v-if="!isMobile && !isWebView">
              <img
                src="@/assets/img/icons/play-store.svg"
                id="play-store"
                @click.prevent="openApp('play-store')"
              />
              <img
                src="@/assets/img/icons/apple.svg"
                id="apple"
                @click.prevent="openApp('apple')"
              />

              <b-tooltip target="play-store" title="App Android" placement="left" />
              <b-tooltip target="apple" title="App iOS" placement="left" />
            </div>
            <a href="#" class="central-ajuda flex-1" @click.prevent="openHelp">
              <img src="@/assets/img/icons/help.svg" />
              {{ $t("views.dashboard.text_985") }}
            </a>
          </div>
        </BaseHeader>
        <div class="conteudo">
          <div class="center">
            <!-- Card Identidy Validation Mobile -->
            <div v-if="pendingIdentityValidationMobile" class="card-validation">
              <p v-if="statusValidationDisapproved">
                {{ $t("views.dashboard.text_1001") }}
              </p>
              <p v-else>
                {{ $t("views.seller.my_account.text_1235_1") }}
              </p>
              <BaseButton
                variant="info2"
                @click="openVerification"
                v-if="!isWebView || (isWebView && getFlags.includes('idwall'))"
              >
                {{ $t("views.dashboard.text_987") }}
              </BaseButton>
            </div>
            <!-- Card Company Validation Mobile -->
            <div v-if="pendingIdentityValidationCompanyMobile" class="card-validation">
              <p v-if="statusValidationDisapproved">
                {{ $t("views.dashboard.text_1001") }}
              </p>
              <p v-else>
                {{ $t("views.dashboard.text_1235_1") }} {{ isHeaven ? "Heaven" : "Greenn" }}.
              </p>
              <BaseButton variant="info2" @click="openVerification">
                {{ $t("views.dashboard.text_987") }}
              </BaseButton>
            </div>
            <!-- Banner -->
            <a
              :href="url_redirect"
              target="_blank"
              class="banner"
              :class="{ heaven: isHeaven }"
              data-anima="bottom"
            >
              <img v-if="banner_url !== ''" :src="banner_url" alt="banner" />
              <!-- Loading -->
              <div v-else class="banner-default-loading banner" alt="banner">
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </a>
            <DonationCampaign
              v-if="donateFlag && !$store.getters.setPanel"
              :userId="userId"
              :isMobile="isMobile"
            />
            <!-- Today Sales -->
            <div v-if="!isClient" class="indicadores">
              <!-- Sales of Today -->
              <div class="item" :class="{ heaven: isHeaven }">
                <img v-if="!isHeaven" src="@/assets/img/icons/bag-sale.svg" />
                <img v-else src="@/assets/img/icons-heaven/bag-sale.svg" />
                <p>{{ $t("views.dashboard.text_988") }}</p>
                <div v-if="statementsToday">
                  <h4 v-if="saldoOk">
                    {{ currency.currency_symbol }}
                    {{ formatMoney(statementsToday) }}
                  </h4>
                  <h4 v-else id="no-saldo">-</h4>
                </div>
                <div v-else>
                  <h4 v-if="saldoOk">{{ currency.currency_symbol }} 0,00</h4>
                  <h4 v-else id="no-saldo">-</h4>
                </div>
              </div>
              <!-- Sales of last 30th days -->
              <div class="item" :class="{ heaven: isHeaven }">
                <img v-if="!isHeaven" src="@/assets/img/icons/wallet.svg" />
                <img v-else src="@/assets/img/icons-heaven/wallet.svg" />
                <p>{{ $t("views.dashboard.text_989") }}</p>
                <div v-if="statementsLastMonth">
                  <h4 v-if="saldoOk">
                    {{ currency.currency_symbol }}
                    {{ formatMoney(statementsLastMonth) }}
                  </h4>
                  <h4 v-else id="no-saldo">-</h4>
                </div>
                <div v-else>
                  <h4 v-if="saldoOk">{{ currency.currency_symbol }} 0,00</h4>
                  <h4 v-else id="no-saldo">-</h4>
                </div>
              </div>
            </div>
            <!-- Cashback infos -->
            <cashback v-if="isMobile && !isClient" class="mt-3" />
            <!-- Card Transactions Mobile -->
            <div
              v-if="isMobile && !isClient"
              class="card-transacoes my-4 w-100"
              :class="{ heaven: isHeaven }"
            >
              <div class="saldos">
                <!-- My Balance -->
                <div class="mb-3 d-flex justify-content-between align-items-center">
                  <span>{{ $t("views.dashboard.text_990") }}</span>
                  <!-- <SelectCurrency v-show="isHeaven"
                    :currency="currency"
                    @update-coin="updateCoin"
                  /> -->
                </div>
                <div class="saldo-livre" :class="{ heaven: isHeaven }">
                  <div v-if="statementsAvailableCount">
                    <h5 v-if="saldoOk">
                      {{ currency.currency_symbol }}
                      {{ formatMoney(statementsAvailableCount) }}
                    </h5>
                    <h5 v-else id="no-saldo">-</h5>
                  </div>
                  <div v-else>
                    <h5 v-if="saldoOk">{{ currency.currency_symbol }} 0,00</h5>
                    <h5 v-else id="no-saldo">-</h5>
                  </div>

                  <div
                    @click="saldoOk = !saldoOk"
                    class="btn-esconde"
                    :class="{ ativo: saldoOk }"
                  ></div>
                </div>
                <!-- Blocked Balance -->
                <div class="saldo-block">
                  <span>{{ $t("views.dashboard.text_991") }}</span>
                  <div v-if="statementsBlockCount">
                    <h5 v-if="saldoOk">
                      {{ currency.currency_symbol }}
                      {{ formatMoney(statementsBlockCount) }}
                    </h5>
                    <h5 v-else id="no-saldo" class="block-prelod">-</h5>
                  </div>
                  <div v-else>
                    <h5 v-if="saldoOk">{{ currency.currency_symbol }} 0,00</h5>
                    <h5 v-else id="no-saldo" class="block-prelod">-</h5>
                  </div>
                </div>
              </div>
              <!-- Bar/Circle Chart Title -->
              <div class="titulo-grafico">
                <span>{{ $t("views.dashboard.text_992") }}</span>
                <span class="periodo-grafico">{{ $t("views.dashboard.text_993") }} </span>
              </div>
              <!-- Bar/Circle Chart -->
              <div class="grafico-bar">
                <BarChart :vendas_device="vendas_device" v-show="!loading_statements" />
                <!-- Loading -->
                <div class="container-loading" v-if="loading_statements">
                  <b-spinner label="Loading..."></b-spinner>
                </div>
              </div>
              <!-- Recent Sales -->
              <div class="transacoes-recentes">
                <div class="titulo">
                  <span>{{ $t("views.dashboard.text_994") }}</span>
                  <img src="@/assets/img/icons/transacoes.svg" />
                </div>
                <div class="transacao">
                  <!-- Recent Sales List -->
                  <template v-if="vendas_recentes.length">
                    <div
                      v-for="venda in vendas_recentes"
                      :key="venda.id"
                      class="item"
                      @click="openSale(venda.id)"
                    >
                      <div class="dados-transacao">
                        <img
                          v-if="venda.product && venda.product.images.length"
                          :src="venda.product.images[0].path"
                          class="icone"
                        />
                        <div v-else class="icone">
                          <img src="@/assets/img/icons/imagem-modal.svg" class="erro-img" />
                        </div>

                        <div class="dado">
                          <div class="up">
                            <h5>
                              {{
                                venda.product.name.length > 22
                                  ? venda.product.name.substr(0, 22) + "..."
                                  : venda.product.name
                              }}
                            </h5>
                            <span
                              >{{ currency.currency_symbol }}
                              {{ formatMoney(venda.amount) }}
                            </span>
                          </div>
                          <div class="down">
                            <span>{{ nameClient(venda.client.name) }}</span>
                            <span>{{ venda.updated_at | moment }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- See All Sales Button -->
                  <router-link to="/vendas" class="button ver-vendas" :class="{ heaven: isHeaven }">
                    {{ $t("views.dashboard.text_995") }}
                  </router-link>
                </div>
                <div class="transacao" v-if="vendas_recentes_load">
                  <div class="item load">
                    <div class="dados-transacao">
                      <div class="icone"></div>
                      <div class="dado">
                        <div class="up">
                          <h5>-</h5>
                          <span>-</span>
                        </div>
                        <div class="down">
                          <span>-</span>
                          <span>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item load">
                    <div class="dados-transacao">
                      <div class="icone"></div>
                      <div class="dado">
                        <div class="up">
                          <h5>-</h5>
                          <span>-</span>
                        </div>
                        <div class="down">
                          <span>-</span>
                          <span>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item load">
                    <div class="dados-transacao">
                      <div class="icone"></div>
                      <div class="dado">
                        <div class="up">
                          <h5>-</h5>
                          <span>-</span>
                        </div>
                        <div class="down">
                          <span>-</span>
                          <span>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <span v-if="!isClient" class="info-week">{{ $t("views.dashboard.text_993") }}</span>
            <!-- AreaChart -->
            <div v-if="!isClient" class="grafico-area">
              <AreaChart
                ref="areaChart"
                v-if="!loading_statements"
                :currency="currency.currency_symbol"
              />
              <!-- Loading -->
              <div class="container-loading" v-if="loading_statements">
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </div>
            <!-- Top 5 Products -->
            <div v-if="top_products.length && !isClient" class="top">
              <h4>{{ $t("views.dashboard.text_996") }}</h4>
              <div
                v-for="(produto, index) in top_products"
                :key="index"
                class="top-lista d-block d-md-flex"
              >
                <div class="dados-produto">
                  <img
                    v-if="
                      produto.product && produto.product.images && produto.product.images.length
                    "
                    :src="produto.product.images[0].path"
                    class="icone-imagem"
                  />
                  <div v-else class="icone-imagem">
                    <img src="@/assets/img/icons/imagem-modal.svg" class="erro-img" />
                  </div>
                  <div class="dado" v-if="produto.product">
                    <h5>{{ produto.product.name }}</h5>
                    <span>{{ produto.product.category.name }}</span>
                  </div>
                  <div v-else>
                    <h5>{{ $t("views.dashboard.text_997") }}</h5>
                    <span>-</span>
                  </div>
                </div>

                <div class="valores d-flex d-md-block justify-content-between mt-3 mt-md-0">
                  <h5 v-if="produto.total">
                    {{ currency.currency_symbol }}
                    {{ formatMoney(produto.total) }}
                  </h5>
                  <h5 v-if="!produto.total">{{ currency.currency_symbol }} 0,00</h5>

                  <span>
                    {{ produto.count }}
                    {{ $t("views.dashboard.text_2377") }}</span
                  >
                </div>
              </div>
            </div>
            <div v-if="top_products_load && !isClient" class="top">
              <div class="top-lista load">
                <div class="dados-produto">
                  <img src="" class="icone" />
                  <div class="dado">
                    <h5>-</h5>
                    <span>-</span>
                  </div>
                </div>

                <div class="valores">
                  <h5>-</h5>
                  <span>-</span>
                </div>
              </div>
              <div class="top-lista load">
                <div class="dados-produto">
                  <img src="" class="icone" />
                  <div class="dado">
                    <h5>-</h5>
                    <span>-</span>
                  </div>
                </div>

                <div class="valores">
                  <h5>-</h5>
                  <span>-</span>
                </div>
              </div>
              <div class="top-lista load">
                <div class="dados-produto">
                  <img src="" class="icone" />
                  <div class="dado">
                    <h5>-</h5>
                    <span>-</span>
                  </div>
                </div>

                <div class="valores">
                  <h5>-</h5>
                  <span>-</span>
                </div>
              </div>
              <div class="top-lista load">
                <div class="dados-produto">
                  <img src="" class="icone" />
                  <div class="dado">
                    <h5>-</h5>
                    <span>-</span>
                  </div>
                </div>

                <div class="valores">
                  <h5>-</h5>
                  <span>-</span>
                </div>
              </div>
            </div>
          </div>

          <div class="border-feature-opportunites">
            <CardWantBeSeller v-if="isClient" />
            <!-- Card Identity Validation -->
            <div v-if="pendingIdentityValidationNotMobile" class="card-validation" data-anima="top">
              <p v-if="statusValidationDisapproved && existBiometry">      
                {{ $t("views.dashboard.text_1001") }}
              </p>
              <p v-else>
                {{ $t("views.dashboard.text_5000") }}
              </p>
              <BaseButton v-if="!$store.getters.setPanel" variant="info2" @click="openVerification">
                {{ $t("views.dashboard.text_987") }}
              </BaseButton>
            </div>
            <!-- Card Company Validation -->
            <div v-if="pendingIdentityValidationCompany" class="card-validation">
              <p v-if="statusValidationDisapproved">
                {{ $t("views.dashboard.text_1001") }}
              </p>
              <p v-else>
                {{ $t("views.dashboard.text_1001") }} {{ isHeaven ? "Heaven" : "Greenn" }}.
              </p>
              <BaseButton variant="info2" @click="openVerification">
                {{ $t("views.dashboard.text_987") }}
              </BaseButton>
            </div>
            <!-- Cashback infos -->
            <cashback v-if="!isMobile && !isClient" />
            <!-- Greenn Profit -->
            <FeatureOpportunites v-if="!isMobile && getFlags.includes('opportunity_settings')" />
            <!-- Card Transactions -->
            <div
              v-if="!isMobile && !isClient"
              class="card-transacoes"
              :class="{ heaven: isHeaven }"
            >
              <div class="saldos">
                <!-- My Balance -->
                <div class="d-flex justify-content-between align-items-center">
                  <span>{{ $t("views.dashboard.text_990") }}</span>
                  <!-- <SelectCurrency v-show="isHeaven"
                    :currency="currency"
                    @update-coin="updateCoin"
                  /> -->
                </div>
                <!-- Free Balance -->
                <div class="saldo-livre" :class="{ heaven: isHeaven }">
                  <div v-if="statementsAvailableCount">
                    <h5 v-if="saldoOk">
                      {{ currency.currency_symbol }}
                      {{ formatMoney(statementsAvailableCount) }}
                    </h5>
                    <h5 v-else id="no-saldo">-</h5>
                  </div>
                  <div v-else>
                    <h5 v-if="saldoOk">{{ currency.currency_symbol }} 0,00</h5>
                    <h5 v-else id="no-saldo">-</h5>
                  </div>

                  <div
                    @click="saldoOk = !saldoOk"
                    class="btn-esconde"
                    :class="{ ativo: saldoOk }"
                  ></div>
                </div>
                <!-- Blocked Balance -->
                <div class="saldo-block">
                  <span>{{ $t("views.dashboard.text_991") }}</span>
                  <div v-if="statementsBlockCount">
                    <h5 v-if="saldoOk">
                      {{ currency.currency_symbol }}
                      {{ formatMoney(statementsBlockCount) }}
                    </h5>
                    <h5 v-else id="no-saldo" class="block-prelod">-</h5>
                  </div>
                  <div v-else>
                    <h5 v-if="saldoOk">{{ currency.currency_symbol }} 0,00</h5>
                    <h5 v-else id="no-saldo" class="block-prelod">-</h5>
                  </div>
                </div>
              </div>
              <!-- Bar/Circle Chart Title -->
              <div class="titulo-grafico">
                <span>{{ $t("views.dashboard.text_992") }}</span>
                <span class="periodo-grafico">{{ $t("views.dashboard.text_993") }} </span>
              </div>
              <!-- Bar/Circle Chart -->
              <div class="grafico-bar">
                <BarChart :vendas_device="vendas_device" v-show="!loading_statements" />
                <!-- Loading -->
                <div class="container-loading" v-if="loading_statements">
                  <b-spinner label="Loading..."></b-spinner>
                </div>
              </div>

              <div class="transacoes-recentes">
                <!-- Recent Sales Title-->
                <div class="titulo">
                  <span>{{ $t("views.dashboard.text_2378") }}</span>
                  <img src="@/assets/img/icons/transacoes.svg" />
                </div>
                <div class="transacao">
                  <!-- Recent Sales List -->
                  <template v-if="vendas_recentes.length">
                    <div
                      v-for="venda in vendas_recentes"
                      :key="venda.id"
                      class="item"
                      @click="openSale(venda.id)"
                    >
                      <div class="dados-transacao">
                        <img
                          v-if="venda.product.images.length"
                          :src="venda.product.images[0].path"
                          class="icone"
                        />
                        <div v-else class="icone">
                          <img src="@/assets/img/icons/imagem-modal.svg" class="erro-img" />
                        </div>
                        <div class="dado">
                          <div class="up">
                            <h5>
                              {{
                                venda.product.name.length > 22
                                  ? venda.product.name.substr(0, 22) + "..."
                                  : venda.product.name
                              }}
                            </h5>
                            <span
                              >{{ currency.currency_symbol }}
                              {{ formatMoney(venda.amount) }}
                            </span>
                          </div>
                          <div class="down">
                            <span>{{ nameClient(venda.client.name) }}</span>
                            <span>{{ venda.updated_at | moment }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- See All Sales Button -->
                  <router-link to="/vendas" class="button ver-vendas" :class="{ heaven: isHeaven }">
                    {{ $t("views.dashboard.text_2379") }}
                  </router-link>
                </div>
                <!-- Loading -->
                <div class="transacao" v-if="vendas_recentes_load">
                  <div class="item load">
                    <div class="dados-transacao">
                      <div class="icone"></div>
                      <div class="dado">
                        <div class="up">
                          <h5>-</h5>
                          <span>-</span>
                        </div>
                        <div class="down">
                          <span>-</span>
                          <span>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item load">
                    <div class="dados-transacao">
                      <div class="icone"></div>
                      <div class="dado">
                        <div class="up">
                          <h5>-</h5>
                          <span>-</span>
                        </div>
                        <div class="down">
                          <span>-</span>
                          <span>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item load">
                    <div class="dados-transacao">
                      <div class="icone"></div>
                      <div class="dado">
                        <div class="up">
                          <h5>-</h5>
                          <span>-</span>
                        </div>
                        <div class="down">
                          <span>-</span>
                          <span>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="isMobile && floatApp && !isClient && !isWebView"
            data-anima="bottom"
            class="float-app"
          >
            <div class="close-icon" @click="floatApp = false">
              <img src="@/assets/img/icons/fechar.svg" />
            </div>
            <div class="text">
              <p>{{ $t("views.dashboard.text_998") }}</p>
              <p>{{ $t("views.dashboard.text_999") }}</p>
            </div>
            <div class="acoes">
              <BaseButton
                variant="black"
                v-if="detectOs() == 'iOS' || detectOs() == 'Desktop'"
                @click="openApp('apple')"
              >
                {{ detectOs() != "Desktop" ? $t("views.dashboard.text_2170") : "iOS" }}
              </BaseButton>
              <BaseButton
                variant="black"
                v-if="detectOs() == 'Android' || detectOs() == 'Desktop'"
                @click="openApp('play-store')"
                >{{ detectOs() != "Desktop" ? $t("views.dashboard.text_2170") : "Android" }}
              </BaseButton>
            </div>
          </div>
        </div>
        <RecentPurchases v-if="isClient" />
      </div>
    </transition>
    <UserValidation />
    <Informative />
    <ModalValidation @close="getStatusValidation('close_modal')" />
    <CallEvaluation />
    <ModalIndicationsOnboard />
    <ModalAccountType
      :id="id_modal_account_type"
      :recebedorType="account_type"
      @close="closeAccountType"
    />
    <ModalPJAccount />
  </div>
</template>

<script>
import { EventBus } from "@/main.js";
import subUserRedirect from "@/utils/subUserRedirect";

import Cashback from "@/components/Seller/Cashback/Cashback.vue";
import RecentPurchases from "@/components/Client/Dashboard/RecentPurchases.vue";
import CardWantBeSeller from "@/components/Client/Dashboard/CardWantBeSeller.vue";
import Informative from "@/components/informative";
import Money from "@/mixins/money";
import AreaChart from "@/components/chart/AreaChart.vue";
import BarChart from "@/components/chart/BarChart.vue";
import SelectCurrency from "@/components/SelectCurrency.vue";
import ModalIndicationsOnboard from "@/components/Seller/Indications/ModalIndicationsOnboard.vue";
import ModalPJAccount from "@/components/Seller/Settings/ModalPJAccount.vue";
import ModalAccountType from "@/components/Seller/Settings/ModalAccountType.vue";
import DonationCampaign from "@/components/Seller/Donation/DonationCampaign.vue";
import crisp from "@/mixins/crisp.js";
import moment from "moment";
import _ from "lodash";
import Cookies from "js-cookie";

import UserValidation from "@/components/Seller/MyAccount/modals/UserValidation.vue";

import UserService from "@/services/resources/UserService";
import MetaService from "@/services/resources/MetaService";
import SubUserService from "@/services/resources/SubUsersService";
import ValidationService from "@/services/resources/ValidationService";
import AuthServiceClub from "@/services/resources/AuthServiceClub";
import AuthServiceEnvios from "@/services/resources/AuthServiceEnvios";
import DashboardService from "@/services/resources/DashboardService";
import UserSettingsService from "@/services/resources/UserSettingsService";
import StepService from "@/services/resources/StepService";
import gtagSend from "@/utils/gtagSend";

import { mapGetters, mapActions, mapState } from "vuex";

import Raphael from "raphael/raphael";

global.Raphael = Raphael;

const serviceUser = UserService.build();
const serviceMeta = MetaService.build();
const serviceSubUser = SubUserService.build();
const serviceValidation = ValidationService.build();
const serviceClub = AuthServiceClub.build();
const serviceEnvios = AuthServiceEnvios.build();
const serviceDashboard = DashboardService.build();
const serviceUserSettings = UserSettingsService.build();

const serviceStep = StepService.build();
import hasFlag from "@/utils/validateFlag.js";
import IdWall from "../../mixins/IdWall";
import FeatureOpportunites from "@/components/Views/Opportunities/FeatureOpportunites.vue";

export default {
  name: "Dashboard",
  components: {
    RecentPurchases,
    CardWantBeSeller,
    AreaChart,
    BarChart,
    Informative,
    SelectCurrency,
    ModalIndicationsOnboard,
    ModalPJAccount,
    UserValidation,
    Cashback,
    ModalAccountType,
    DonationCampaign,
    FeatureOpportunites,
  },
  data() {
    return {
      banner_url: "",
      url_redirect: "",
      loading: true,
      floatApp: true,
      loading_statements: false,
      status_validation: {
        status: "status",
      },
      vendas_semana: [],
      vendas_device: [],
      top_products: [],
      top_products_load: false,
      statementsBlockCount: 0,
      statementsAvailableCount: 0,
      statementsToday: 0,
      statementsLastMonth: 0,
      vendas_recentes: [],
      vendas_recentes_load: false,
      saldoOk: true,
      client: {
        width: 0,
      },
      currency: {
        id: 1,
        currency: "BRL",
        currency_symbol: "R$",
      },
      id_modal_account_type: "modal-account-type-dashboard",
    };
  },
  mixins: [Money, crisp, IdWall],
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YY HH:ss");
    },
  },
  computed: {
    ...mapGetters({
      getFlags: "getFlags",
      kyc_personal_checklist: "getKycPersonalChecklist",
      errorCreateCelcoin: "getErrorCreateCelcoin",
      errorKycCelcoinPF: "getErrorKycCelcoinPF",
      errorKycCelcoinPJ: "getErrorKycCelcoinPJ",
    }),
    ...mapState({
      account_type: (state) => state.account_type,
    }),
    donateFlag() {
      return hasFlag("_rs_donate");
    },
    isWebView() {
      return window.isWebView == true;
    },
    userId() {
      return this.$store.getters.getProfile.id;
    },
  },
  methods: {
    ...mapActions(["fetchUserSettings", "checkTerms", "closeModal"]),
    closeAccountType(type) {
      this.$store.dispatch("setAccountType", type);

      const pendingPjDocuments =
        (type === "PENDING_PJ" || type === "PJ") &&
        this.userLogged.company_id &&
        !this.hasCompanyDocuments;

      if (pendingPjDocuments) {
        this.openPJModal();
      } else if (this.errorKycCelcoinPF.length) {
        this.openVerification();
      } else if (this.errorKycCelcoinPJ.length) {
        this.openPJModal();
      }
    },
    sendGtag() {
      if (this.userLogged) {
        const address = this.userLogged.address || {};
        const addressString = `Street: ${address.street}, 
          Number: ${address.number}, 
          Neighborhood: ${address.neighborhood}, 
          City: ${address.city}, 
          State: ${address.state}, 
          Zip: ${address.zip_code}`;
        gtagSend("dashboard_inicial_acessado", {
          ID_Cliente: this.userLogged?.id,
          name: this.userLogged?.name,
          email: this.userLogged?.email.replace("@", "%40"),
          cellphone: this.userLogged?.cellphone,
          address: addressString,
          gerente_de_negocios: this.userLogged?.seller_sec_user_id,
          gerente_de_contas: this.userLogged?.costumer_sucess_sec_user_id,
          marco_atingido: this.userLogged?.user_checks[0]
            ? this.userLogged?.user_checks[0]?.flow
            : "",
          data_do_marco_atingido: this.userLogged?.user_checks[0]
            ? moment(this.userLogged?.user_checks[0]?.updated_at).format("DD/MM/YYYY HH:mm:ss")
            : "",
          data_de_cadastro: moment(this.userLogged?.created_at).format("DD/MM/YYYY HH:mm:ss"),
          status_de_antecipacao: this.userLogged?.antecipation_requests[0]?.status,
          cluster_atual: this.userLogged?.metas[0]?.meta_value,
        });
      }
    },
    openAccountTypeModal() {
      this.$bvModal.show(this.id_modal_account_type);
    },
    openPJModal() {
      this.$bvModal.show("modal-pj-account");
    },
    addGTM() {
      const script = document.createElement("script");
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TRVTZVCJ');`;

      const noscript = document.createElement("noscript");
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TRVTZVCJ" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

      document.body.appendChild(script);
      document.body.appendChild(noscript);
    },
    existExternalParams() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const type = urlParams.get("type");
      const code = urlParams.get("code");
      const parent_id = urlParams.get("parent_id");
      if (type === "club" || type === "envios") {
        this.externalSubmit(type);
      } else if (parent_id && code) {
        this.modalSubUser();
      }
    },
    modalSubUser() {
      if (document.getElementById("modal-group-accept") === null) {
        this.$root.$emit("bv::show::modal", "modal-group-accept", "#btnShow");
      }
    },
    externalSubmit(type) {
      var service = "";
      if (type === "club") {
        service = serviceClub;
      } else {
        service = serviceEnvios;
      }

      service
        .toClub()
        .then((resp) => {
          if (resp.string) {
            if (type === "club") {
              window.location.href =
                process.env.VUE_CLUB_API_HOST + "?rdr=" + resp.string + "&type=greenn";
            } else {
              window.location.href =
                process.env.VUE_ENVIOS_API_HOST + "/login?rdr=" + resp.string + "&type=greenn";
            }
          }
        })
        .catch(() => {});
    },
    openVerification() {
      const openPj =
        !this.isClient &&
        this.userLogged.company_id &&
        (this.$store.state.account_type === "PJ" ||
          this.$store.state.account_type === "PENDING_PJ") &&
        !this.hasCompanyDocuments &&
        ["APPROVED", "REQUESTED"].includes(this.status_validation.status);
      if (this.isWebView) {
        if (this.getFlags.includes("idwall")) {
          if (openPj) {
            window.flutter_inappwebview.callHandler("openPJVerification");
          } else {
            window.flutter_inappwebview.callHandler("completeCheck");
          }
        } else {
          if (openPj) {
            window.flutter_inappwebview.callHandler("openPJVerification");
          } else {
            if (!this.acceptedTerm) {
              this.$bvModal.show("identity-validation");
            }
          }
        }
      } else {
        if (this.getFlags.includes("idwall")) {
          this.$bvModal.show("user-validation");
        } else {
          if (openPj) {
            this.openPJModal();
          } else {
            if (!this.acceptedTerm) {
              this.$bvModal.show("identity-validation");
            }
          }
        }
      }
    },
    async getStatusValidation(origem = null) {
      if (this.$store.state.user.user.type === "CLIENT") return;
      let user = {};
      if (!localStorage.user) {
        user = await this.$store.dispatch("userRequest");
      } else {
        user = JSON.parse(localStorage.user);
      }
      if (user.type === "CLIENT") return;

      serviceValidation
        .read({ id: "status" })
        .then((res) => {
          this.status_validation = res;
          if (!this.status_validation.status || this.status_validation.status == "status") {
            this.status_validation = {
              status: "INITIATED",
              observation: "",
            };
          }

          if (
            origem === "close_modal" &&
            (this.status_validation.status === "INITIATED" ||
              this.status_validation.status === "REQUESTED")
          ) {
            const pendingKycPersonalChecklist =
              !this.kyc_personal_checklist && this.getFlags.includes("recipient_data");

            const pendingPjDocuments =
              (this.$store.state.account_type === "PENDING_PJ" ||
                this.$store.state.account_type === "PJ") &&
              this.userLogged.company_id &&
              !this.hasCompanyDocuments;
            if (this.getFlags.includes("recipient_data")) {
              if (pendingKycPersonalChecklist) {
                this.openAccountTypeModal();
              } else if (!pendingKycPersonalChecklist && pendingPjDocuments) {
                this.openPJModal();
              } else if (this.errorKycCelcoinPJ.length) {
                this.openPJModal();
              }
            }
          }
        })
        .catch((err) => console.error(err));
    },
    fetchMetas() {
      let data = {
        id: "",
      };

      const stepToRoutesMapping = [
        { key: "welcome", value: "welcome" },
        { key: "onboarding_document", value: "document" },
        { key: "onboarding_objective", value: "mainobjetive" },
        { key: "onboarding_revenue", value: "alreadysellonline" },
        { key: "onboarding_social", value: "mainsocialnetwork" },
      ];

      serviceStep.read(data).then((response) => {
        const pendingStep = stepToRoutesMapping.find((i) => {
          if (i.key === response[0]) {
            return i;
          }
        });
        if (pendingStep && this.$store?.state?.user?.user?.type !== "CLIENT") {
          this.$router.push(`/boas-vindas?step=${pendingStep.value}`);
        }
      });
    },
    detectOs() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "Desktop";
    },
    openHelp() {
      window.open("https://greenn.crisp.help/pt-br/");
    },
    openApp(type) {
      if (type == "apple") {
        window.open("https://apps.apple.com/us/app/greenn-adm/id6450507558");
      }
      if (type == "play-store") {
        window.open("https://play.google.com/store/apps/details?id=br.com.greenn");
      }
    },
    nameClient(name_client) {
      return name_client.toString().length > 20
        ? name_client.toString().substr(0, 20) + "..."
        : name_client;
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    date(date) {
      return moment(date).format("DD/MM/YY");
    },
    openSale(id_venda) {
      this.$router.push({
        path: "/vendas",
        query: {
          s_id: id_venda,
        },
      });
    },
    async fetchDataDash() {
      // products
      this.top_products_load = true;
      this.top_products = [];

      // sales
      let array = [];
      this.vendas_recentes_load = true;

      // header
      this.loading_statements = true;
      this.saldoOk = false;
      this.vendas_semana = [];
      this.vendas_device = [];

      // date params
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);
      let lastMonth = new Date();
      lastMonth.setDate(today.getDate() - 30);
      lastMonth.setHours(0, 0, 0, 0);

      let data = {
        types: ["banner_url_disk", "top_products", "sales", "statements"],
        today: moment.tz(today, "America/Sao_Paulo").utc().format("YYYY-MM-DDT00:00:00-03:00"),
        last_week: moment
          .tz(lastWeek, "America/Sao_Paulo")
          .utc()
          .format("YYYY-MM-DDT00:00:00-03:00"),
        last_month: moment
          .tz(lastMonth, "America/Sao_Paulo")
          .utc()
          .format("YYYY-MM-DDT00:00:00-03:00"),
        currency_id: this.isHeaven ? 2 : 1,
      };

      await serviceDashboard
        .search(data)
        .then((res) => {
          // banner
          this.banner_url = res?.upload_path;
          this.url_redirect = res?.url_redirect;

          // products
          this.top_products = res?.top_products;

          // sales
          if (res?.salesLastWeek.length) {
            res.salesLastWeek.forEach((item, index) => {
              if (index < 5) array.push(item);
            });
          }

          this.statementsAvailableCount = res?.statementsAvailable;
          this.statementsBlockCount = res?.statementsBlock;
          this.statementsToday = res?.statementsToday;
          this.statementsLastMonth = res?.statementsLastMonth || 0;

          this.vendas_semana = res?.statementsLastWeekDetail;
          this.vendas_device = [
            {
              device: "Desktop",
              count: res?.statementsDesktopLastWeek,
            },
            {
              device: "Mobile",
              count: res?.statementsMobileLastWeek,
            },
          ];
          this.saldoOk = true;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading_statements = false;
          setTimeout(() => {
            this.$refs.areaChart.mountedChart(this.vendas_semana);
          }, 1000);

          this.top_products_load = false;
          this.vendas_recentes = array;
          this.vendas_recentes_load = false;
          this.loading = false;
        });
    },
    fetchBanner() {
      let data = {
        types: ["banner_url_disk"],
      };

      serviceDashboard
        .search(data)
        .then((response) => {
          this.banner_url = response.upload_path;
          this.url_redirect = response.url_redirect;
        })
        .catch((err) => console.error(err));
    },
    openPanel(sale_id) {
      this.$emit("openPanel", sale_id);
    },
    debounce: _.debounce(function () {
      this.sendLocalStorage();
    }, 5000),
    // funcção para enviar os dados do localStorage para o back
    sendLocalStorage() {
      if (this.$store.getters.getProfile.id || localStorage.user) {
        const user = JSON.parse(localStorage.user);
        let data = {
          id: this.$store.getters.getProfile.id || user.id,
          local_storage: JSON.stringify(localStorage),
          ignore_phone_validation: true,
          customEmail: this.$store.state.user.customEmail,
        };
        serviceUser.update(data);
      }
    },
    async getPermissions() {
      if (this.$store.getters.setPanel) {
        await serviceSubUser
          .read(`/get-permissions/${this.$store.getters.setPanel}`)
          .then((response) => {
            Cookies.set("setPanelPermission", JSON.stringify(response), {
              expires: 7,
            });
            const routeName = subUserRedirect(response);
            if (routeName.route !== "dashboard") {
              this.$router.push(routeName.route);
            } else {
              this.initAll();
            }
            this.$root.$emit("reset-permissions");
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.initAll();
      }
    },
    async initAll() {
      if (!this.isClient) {
        this.getStatusValidation() && this.getFlowValidation();
      }

      // metas
      this.fetchMetas();

      this.fetchBanner();
      if (!this.isClient) {
        await this.fetchDataDash();
      }

      this.debounce();

      if (!this.isClient && this.userLoggedType === "SELLER") {
        window.$crisp = [];

        this.existExternalParams();

        // add crisp no greenn
        let notify = document.getElementById("1WZET722PJibpmL_crisp");
        if (notify === null) {
          this.getEvaluation(true).then((resp) => {
            if (resp.is_evaluation) {
              window.$crisp.push(["do", "session:reset"]);
            }
            this.crispWitUser();
          });

          this.appendScriptCrisp();
        } else {
          const crisp = document.querySelector(".crisp-client");
          if (crisp) {
            crisp.style.display = "block";
          }
          this.getEvaluation(true).then((resp) => {
            if (resp.is_evaluation) {
              window.$crisp.push(["do", "session:reset"]);
            }
            this.crispWitUser();
          });
        }
        // fim crisp
      }
    },
    /**
     * Refresh dashboard data with selected currency filter
     * @param coin - Object - object of selected currency
     * @return no return
     * @author Sandro Souza
     */
    async updateCoin(coin) {
      this.currency = coin;
      await this.fetchDataDash();
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  async mounted() {
    const $filter = ["INDICATION", "ACCOUNT_TYPE", "CHARGEBACK_EMAIL", "INDICATION_ONBOARD"];
    this.checkTerms();

    await this.$store.dispatch("setUserSettings", $filter);

    EventBus.$on("reset-all", async (data) => {
      await this.getPermissions();
    });

    this.$root.$on("reset-all", async (data) => {
      await this.getPermissions();
    });

    await this.getPermissions();

    if (this.isHeaven) {
      this.currency = {
        id: 2,
        currency: "USD",
        currency_symbol: "$",
      };
    } else {
      this.currency = {
        id: 1,
        currency: "BRL",
        currency_symbol: "R$",
      };
    }

    await this.$store.dispatch("fetchFlags");

    const pendingKycPersonalChecklist =
      !this.kyc_personal_checklist && this.getFlags.includes("recipient_data");

    const pendingPjDocuments =
      (this.$store.state.account_type === "PENDING_PJ" ||
        this.$store.state.account_type === "PJ") &&
      this.userLogged.company_id &&
      !this.hasCompanyDocuments;

    if (this.getFlags.includes("recipient_data")) {
      if (
        this.pendingIdentityValidation &&
        (!this.isWebView ||
          (this.isWebView && this.getFlags.includes("idwall") && this.flowsAreValid))
      ) {
        this.openVerification();
      } else if (!this.pendingIdentityValidation && pendingKycPersonalChecklist) {
        this.openAccountTypeModal();
      } else if (
        !this.pendingIdentityValidation &&
        !pendingKycPersonalChecklist &&
        pendingPjDocuments
      ) {
        this.openPJModal();
      } else if (this.errorCreateCelcoin.length) {
        this.openAccountTypeModal();
      } else if (this.errorKycCelcoinPF.length) {
        this.openVerification();
      } else if (this.errorKycCelcoinPJ.length && !this.hasCompanyDocumentsApproved) {
        this.openPJModal();
      }
    }
    this.sendGtag();
  },
  async created() {
    this.addGTM();

    window.addEventListener("resize", this.handleResize);
    await this.handleResize();

    if (this.$route.query.rdr) {
      let res = await this.$store.dispatch("recoveryHash", { token: this.$route.query.rdr });
      let access_token = "Bearer " + res.token;
      Cookies.set("access_token", access_token, { expires: 365 });
      this.$store.commit("authSuccess", access_token);

      this.$router.push("/dashboard").then(() => {
        window.location.reload();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.border-feature-opportunites {
  .feature-opportunities {
    border: solid var(--yellow-500) 1px !important;
    border-radius: var(--radius-lg) !important;
  }
}
.box-valores {
  display: flex;
  width: 600px;
  justify-content: space-between;
  align-items: center;
}

.item-valor {
  display: block;
}

.item-valor span {
  font-size: 14px;
  font-weight: normal;
  color: var(--gray-200);
}

.item-valor p {
  font-size: 22px;
  font-weight: 600;
  margin: 10px 0;
  text-align: right;
}

// Conteudo Dashboard
.container {
  display: flex;
  flex-direction: column;
}

.conteudo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* Conteudo centralizado */
.center {
  width: 640px;
}

.banner {
  background: linear-gradient(160.37deg, var(--old-500) 20.71%, var(--gray-white) 206.96%);
  width: 100%;
  border-radius: 5px;
  display: flex;
  margin-bottom: 20px;
  text-decoration: none;
  background: var(--gray-10);
  overflow: hidden;
  object-fit: contain;
}

.banner.heaven {
  background: linear-gradient(160.37deg, var(--blue-500) 20.71%, var(--gray-white) 206.96%);
}

.banner-info h4 {
  color: var(--gray-white);
  font-size: 18px;
  font-weight: 600;
}

.banner-info p {
  color: var(--gray-white);
  font-size: 16px;
  font-weight: normal;
  margin: 10px 0 30px 0;
}

.banner-info a {
  padding: 12px 30px;
  background: transparent;
  color: var(--gray-white);
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid var(--gray-white);
  text-decoration: none;
  transition: 0.3s ease;
}

.banner-info a:hover {
  color: var(--gray-white);
  transform: scale(1.05);
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .container {
    width: calc(100% - 100px);
  }
}

@media screen and (max-width: 1219px) and (min-width: 1024px) {
  .container {
    width: calc(100% - 200px);
    margin-left: 200px;
  }
}

@media screen and (max-width: 1024px) {
  .conteudo {
    display: block;
  }

  .center {
    width: 100%;
  }

  .banner-info h4 {
    font-size: 16px;
  }

  .banner-info p {
    font-size: 14px;
    margin: 10px 0 20px 0;
  }

  .banner-info a {
    padding: 10px 20px;
    font-size: 13px;
  }

  .banner-ilustra img {
    height: 150px;
  }
}

.grafico-area {
  margin: 30px 0 40px 0;
  position: relative;
  height: 300px;
}

/* Conteudo lateral */
.article {
  width: 390px;
  background: gray;
}

.info-week {
  font-size: 12px;
  text-align: right;
  display: block;
  margin-top: 15px;
  color: var(--gray-200);
}

.indicadores {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

.indicadores .item {
  background: var(--gray-white);
  border: 1px solid var(--gray-10);
  border-radius: 5px;
  padding: 25px 35px;
  width: 48%;
  transition: 0.3s;
  cursor: default;
}

.indicadores .item:hover {
  transform: scale(1.05);
}

.indicadores .item:first-child {
  background: var(--primary-50);
  border-color: var(--primary-50);
  box-shadow: none;
}

.indicadores .item.heaven:first-child {
  background: var(--blue-50);
  border-color: var(--blue-50);
}

.indicadores .item p {
  display: flex;
  font-size: 14px;
  font-weight: normal;
  margin: 12px 0;
}

.indicadores .item p img {
  margin-left: 10px;
}

.indicadores .item h4 {
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .info-week {
    text-align: left;
  }

  .indicadores {
    display: block;
  }

  .indicadores .item {
    width: 100%;
  }

  .indicadores .item + .item {
    margin-top: 10px;
  }
}

.preload {
  background: var(--gray-10);
  border-radius: 5px;
  width: 80%;
  color: transparent;
  animation: preload 0.8s forwards linear infinite alternate;
}

.top {
  margin-bottom: 30px;
}

.top h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.top-lista {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  overflow: hidden;
}

.top-lista + .top-lista {
  border-top: 0.5px solid var(--gray-10);
}

.top-lista .dados-produto {
  display: flex;
  align-items: center;
}

.top-lista .dados-produto .icone {
  padding: 20px;
  background: var(--gray-10);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
}

.icone-imagem {
  background: var(--gray-10);
  padding: 0;
  width: 80px;
  max-width: 80px;
  height: 80px;
  margin-right: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-lista h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

.top-lista span {
  color: var(--gray-200);
  font-size: 13px;
  display: initial;
}

.valores {
  text-align: right;
}

@media screen and (max-width: 768px) {
  .top h4 {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .top-lista .dados-produto .icone {
    padding: 13px;
  }

  .icone-imagem {
    width: 60px;
    max-width: 60px;
    height: 60px;
    margin-right: 30px;
  }

  .top-lista h5 {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .top-lista span {
    font-size: 12px;
    white-space: nowrap;
  }
}

.load .dados-produto img,
.load .dados-transacao img {
  filter: invert(50%);
}

.load .dados-produto .dado h5,
.load .up h5,
.load .dados-produto .dado span,
.load .up span,
.load .down span {
  background: var(--gray-10);
  border-radius: 5px;
  width: 200px;
  color: transparent;
  display: block;
  animation: preload 0.8s forwards linear infinite alternate;
}

.load .up h5 {
  width: 120px;
  height: 15px;
  border-radius: 3px;
}

.load .up span,
.load .down span {
  width: 80px;
  height: 15px;
  border-radius: 3px;
}

.load .down span:nth-child(2) {
  width: 100px;
  height: 15px;
  border-radius: 3px;
}

.load .dados-produto .dado span {
  width: 300px;
}

.load .valores h5,
.load .valores span {
  background: var(--gray-10);
  border-radius: 5px;
  width: 100px;
  color: transparent;
  display: block;
  animation: preload 0.8s forwards linear infinite alternate;
}

.card-transacoes {
  width: 440px;
  background: var(--gray-white);
  border: 1px solid var(--gray-10);
  border-radius: 5px;
  padding: 50px;
}

.card-transacoes.heaven {
  background: var(--blue-50);
  border: 1px solid var(--blue-50);
}

.saldos span {
  font-size: 14px;
  font-weight: 600;
}

.saldo-livre {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 13px auto;
}

.saldo-livre h5 {
  color: var(--old-500);
  font-size: 32px;
  font-weight: 600;
}

.saldo-livre.heaven h5 {
  color: var(--blue-500);
}

#no-saldo {
  background: var(--gray-10);
  width: 220px;
  border-radius: 5px;
  transition: 0.3s;
  color: transparent;
}

.block-prelod {
  width: 100px !important;
}

.saldo-livre .btn-esconde {
  padding: 13px;
  background: var(--gray-10) url("../../assets/img/icons/eye-off.svg") no-repeat center center;
  border: 1px solid var(--gray-10);
  border-radius: 50%;
  cursor: pointer;
  height: 45px;
  width: 45px;
}

.btn-esconde.ativo {
  border: 1px dashed var(--old-800);
  background: var(--gray-10) url("../../assets/img/icons/eye.svg") no-repeat center center;
}

.saldo-block span {
  font-size: 13px;
  font-weight: 600;
}

.saldo-block h5 {
  font-size: 13px;
  color: var(--yellow-500);
  font-weight: 600;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .card-transacoes,
  .card-validation {
    width: 100% !important;
    padding: 40px 20px;
    max-width: 100%;
  }

  .saldo-livre {
    margin: 0 auto;
  }

  .saldo-livre h5 {
    font-size: 24px;
  }

  .ver-vendas {
    width: 100%;
  }
}

/* grafico fake */
.grafico-bar {
  margin: 25px auto;
  position: relative;
  height: 220px;
}

/* Transacoes recentes */

.transacoes-recentes .titulo {
  display: flex;
  justify-content: space-between;
}

.transacoes-recentes .titulo span,
.titulo-grafico span {
  font-weight: 600;
  font-size: 14px;
}

.titulo-grafico {
  margin-top: 30px;
}

.transacao {
  margin: 20px 0;
}

.transacao span {
  font-size: 12px;
  font-weight: normal;
}

.transacao .item {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s;
}

.transacao .item:hover {
  transform: scale(1.02);
}

.transacao .item + .item {
  margin-top: 5px;
  border-top: 0.5px solid var(--gray-10);
}

.dados-transacao {
  display: flex;
  align-items: center;
}

.dados-transacao .icone {
  width: 60px;
  height: 60px;
  background: var(--gray-10);
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.up,
.down {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 235px;
}

.up {
  margin-bottom: 5px;
}

.up h5 {
  font-size: 13px;
  font-weight: 600;
}

.up span {
  font-size: 13px;
  color: var(--gray-200);
  font-weight: normal;
}

.down span {
  font-size: 11px;
  color: var(--gray-200);
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .up,
  .down,
  .dados-transacao {
    width: 100%;
  }

  .dado {
    flex: 1;
  }

  .grafico-area {
    max-width: 100%;
  }
}

.ver-vendas {
  width: 100%;
  margin-top: 20px;
  background: transparent;
  color: var(--old-500);
  border: 1px var(--old-500) solid;

  &:hover {
    background: var(--old-500);
    color: var(--gray-white);
    text-decoration: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
}

.ver-vendas.heaven {
  color: var(--blue-500);
  border: 1px var(--blue-500) solid;

  &:hover {
    background: var(--blue-500);
    color: var(--gray-white);
    text-decoration: none;
  }
}

.erro-img {
  max-width: 50px;
  filter: invert(50%);
}

.icone.erro-img {
  max-width: 35px;
}

// loading
.container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  animation: preload 5s forwards linear infinite alternate;
}

.top-icons,
.top-icons .apps {
  display: flex;
  align-items: center;
  gap: 30px;
}

.top-icons .apps {
  gap: 15px;
}

.top-icons .apps img {
  cursor: pointer;
}

// central de ajuda
.central-ajuda {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  color: var(--old-800);
  font-weight: 600;
  transition: 0.3s;
}

.central-ajuda img {
  max-width: 18px;
  filter: invert(0) brightness(0);
  transition: 0.3s;
}

.central-ajuda:hover {
  color: var(--blue-500);
  text-decoration: none;
}

.central-ajuda:hover img {
  filter: invert(0) brightness(1);
}

@media screen and (max-width: 768px) {
  .central-ajuda {
    max-width: 160px;
    margin-top: 20px;
  }
}

.float-app {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999999 !important;
  background-color: var(--gray-200);
  backdrop-filter: blur(20px);
  box-shadow: 0px 0px 30px 0px var(--old-800);

  width: 100%;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.float-app .close-icon {
  padding: 10px;
  cursor: pointer;
}

.float-app .close-icon img {
  filter: invert(0) brightness(10);
}

.float-app p {
  color: var(--gray-white);
  font-size: 13px;
  font-weight: 600;
}

.float-app p:last-child {
  font-size: 12px;
  font-weight: normal;
}

.float-app .acoes {
  display: flex;
}

.float-app .acoes button {
  height: 40px;
  padding: 0 13px !important;
  font-size: 12px;
  margin-left: 20px !important;
}

.periodo-grafico {
  display: block;
  font-size: 12px !important;
  color: var(--gray-200);
  font-weight: normal !important;
}

.card-validation {
  width: 400px;
  background: var(--blue-50);
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 30px;
  display: flex;
  align-items: left;
  gap: 2px;
}

.card-validation p {
  color: var(--blue-500);
  font-size: 13px;
  line-height: 1.5;
}

.card-validation button {
  height: 40px !important;
  font-size: 13px !important;
  padding: 10px 15px !important;
  margin-top: 15px;
  text-align: center;
}

.banner-default-loading {
  justify-content: center;
  align-items: center;
  height: 130px;
}
</style>
